import React from "react";
import { Link } from "react-router-dom";
import logos from "../assets/images/logo.jpg";
const Nodata = () => {
  return (
    <>
      <div className="errors">
        <div>
          {/* <img src={logos} /> */}
          <h3>No Data Found</h3>
          <p>Please Insert Data from admin Panel</p>
          <p>
            <a href="/">Retry</a>
          </p>
        </div>
      </div>
    </>
  );
};
export default Nodata;
