import "bootstrap/dist/js/bootstrap.bundle.min";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../utils/loading";
import { Link, useParams } from "react-router-dom";
import logo from "../../assets/images/logo.jpg";
import TopNavBar from "./topnav";
const NavBar = () => {
  const { slug } = useParams();
  const [Data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isSticky = (e) => {
    const header = document.querySelector(".navbar");
    const scrollTop = window.scrollY;
    if (header) {
      scrollTop >= 50
        ? header.classList.add("is-sticky")
        : header.classList.remove("is-sticky");
    }
  };
  useEffect(() => {
    getApiData();
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [slug]);
  async function getApiData() {
    try {
      const response = await axios(
        `https://admin.rtpharma.com.np/api/menu/${slug ? slug : ""}`
      );
      setData(response.data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }
  if (loading) return "";
  if (error) return "";

  return (
    <>
      <header>
        <TopNavBar />
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <Link className="navbar-brand" to={"/"}>
              <img src={logo} alt="logo image" className="brand-image" />
            </Link>
            <button
              id="ChangeToggle"
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <div id="navbar-hamburger">
                <span>
                  <i className="fa-solid fa-bars" />
                </span>
              </div>
              <div id="navbar-close" className="hidden">
                <span>
                  <i className="fa-solid fa-xmark" />
                </span>
              </div>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav  mb-lg-0">
                {Data.menu.map((i) => {
                  return (
                    <li className="nav-item" key={i.id}>
                      <Link to={`/${i.slug}`} className="nav-link">
                        {i.title}
                      </Link>
                    </li>
                  );
                })}
                <li className="nav-item portalss">
                  <Link
                    to="https://portal.rtpharma.com.np"
                    target="_blank"
                    className="nav-link "
                  >
                    E-Reporting
                  </Link>
                </li>
              </ul>
              {/* <div className="search-container">
                <form action="/search" method="get">
                  <input
                    className="search expandright"
                    id="searchleft"
                    type="search"
                    name="q"
                    placeholder="Search"
                  />
                  <label className="button searchbutton" htmlFor="searchleft">
                    <span className="mglass">
                      <i className="fa-solid fa-magnifying-glass" />
                    </span>
                  </label>
                </form>
              </div> */}
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
export default NavBar;
