import React, { useEffect, useState } from "react";
import axios from "axios";
import LazyLoad from "react-lazy-load";
import Loading from "../../utils/loading";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";
import { Carousel } from "react-responsive-carousel";
import { Link, useParams, useNavigate } from "react-router-dom";
import Errors from "../../utils/errors";
import BannerImage from "../../assets/images/pharma-cover-1.jpg";
import aboutimage from "../../assets/images/coverimage2.jpg";
import BlogImage from "../../assets/images/news.jpg";
import { TabTitle } from "../../utils/dynamicTitle";
import CountUp from "react-countup";
import POpUp from "../../utils/popup";
const HomePage = () => {
  TabTitle("RT Pharmaceuticals | Home");
  const { slug } = useParams();
  const [Data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    getApiData();
  }, [slug]);
  async function getApiData() {
    try {
      const response = await axios(`https://admin.rtpharma.com.np/api/home`);
      setData(response.data);
      // console.log("res_home", response);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }
  const settingsProducts = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settingsBlogs = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
  };
  if (loading) return <Loading />;
  if (error) return <Errors />;
  return (
    <>
      <POpUp />
      <section className="sec-1">
        <LazyLoad height={480}>
          <Carousel
            infiniteLoop={true}
            autoPlay={true}
            stopOnHover={false}
            interval="5000"
            transitionTime="2000"
            showThumbs={false}
          >
            {Data.banners.map((i) => {
              return (
                <div key={i.id}>
                  <img src={i.DocPath ? i.DocPath : BannerImage} />
                </div>
              );
            })}
          </Carousel>
        </LazyLoad>
      </section>

      <section className="sec-2">
        <div className="container">
          <div className="row">
            <div className="sec-img-2 col col-md-5 col-12">
              <LazyLoad>
                <img
                  src={
                    Data.about !== null
                      ? Data.about.CoverImage
                        ? Data.about.CoverImage
                        : aboutimage
                      : ""
                  }
                  alt="cover image"
                />
              </LazyLoad>
            </div>

            <div className="sec-desc-2 col col-md-7 col-12">
              <h1 className="sec_main_title">
                About <span>RT</span>
              </h1>
              <p>
                {Data.about !== null
                  ? Data.about.Description.replace(/(<([^>]+)>)/gi, "").length >
                    650
                    ? `${Data.about.Description.replace(
                        /(<([^>]+)>)/gi,
                        ""
                      ).substring(0, 650)}...`
                    : Data.about.Description.replace(/(<([^>]+)>)/gi, "")
                  : ""}
              </p>
              <button
                className="custom-btn-1"
                onClick={() => {
                  navigate(`/about`);
                }}
              >
                View More
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="sec-3 text-center">
        <h3>Our Working Areas</h3>
        <h6>Focused on various working areas of medical science</h6>
        <div className="container row row-cols-1 row-cols-md-5 g-4">
          {Data.work_areas
            ? Data.work_areas.map((i) => {
                return (
                  <div className="col" key={i.id}>
                    <div className="card">
                      <div className="bg-img">
                        <div className="card-body d-flex flex-column align-items-center justify-content-center">
                          <div className="imagesss">
                            <img
                              src={i.Image ? i.Image : image1}
                              className="card-img-top rounded-circle mx-auto d-block"
                              alt={i.Title}
                            />
                          </div>
                          <div className="card-title">{i.Title}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </section> */}

      <section className="sec-4">
        <div className="our-products">
          <div className="section_title">
            <h1 className="sec_main_title">
              <span>RT</span> Products
            </h1>
          </div>
          <div className="product-carousel">
            <Slider {...settingsProducts}>
              {Data.products
                ? Data.products.map((i) => {
                    return (
                      <div key={i.id}>
                        <div
                          className="card"
                          onClick={() => {
                            navigate(`products/details/${i.slug}`);
                          }}
                        >
                          <div className="Products_image">
                            <img
                              src={i.featured_image}
                              className="card-img-top"
                              alt={i.title}
                            />
                          </div>
                          <div className="card-body">
                            <h6 className="card-text">{i.title}</h6>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </Slider>
          </div>
        </div>
      </section>
      <section className="sec-5">
        <div className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 g-4">
          {Data.achivement.map((i) => {
            return (
              <div className="col" key={i.count}>
                <div className="card h-100">
                  <h2>
                    <CountUp delay={5} end={i.count} />
                    <sup>+</sup>
                  </h2>
                  <p className="title">{i.title}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <div className="sec-6 container">
        <h1 className="sec_main_title borders">
          <span>RT</span> Blogs
        </h1>
        <div className="row">
          <div className="col col-lg-8 col-md-6 col-12">
            <Slider {...settingsBlogs}>
              {Data.blogs
                ? Data.blogs.map((i) => {
                    return (
                      <div className="card" key={i.id}>
                        <img
                          src={i.featured_image ? i.featured_image : BlogImage}
                          className="card-img-top"
                          alt="Latest News"
                        />
                        <div className="card-body">
                          <h4 className="card-title">{i.title}</h4>
                          <button
                            className="custom-btn-1"
                            onClick={() => {
                              navigate(`blogs/details/${i.slug}`);
                            }}
                          >
                            <i className="fa-solid fa-arrow-right-long" />
                            View More
                          </button>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </Slider>
          </div>
          <div className="col col-lg-4 col-md-6 col-12">
            <div id="wrapper">
              <ul className="list-unstyled">
                {Data.blogs
                  ? Data.blogs.map((i) => {
                      return (
                        <li
                          key={i.id}
                          onClick={() => {
                            navigate(`blogs/details/${i.slug}`);
                          }}
                        >
                          <img src={i.featured_image} />
                          <Link to={`blogs/details/${i.slug}`}>{i.title}</Link>
                        </li>
                      );
                    })
                  : ""}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomePage;
