import React, { useEffect, useState } from "react";
import axios from "axios";
import LazyLoad from "react-lazy-load";
import Loading from "../../../utils/loading";
import { Link, useParams, useNavigate } from "react-router-dom";
import Errors from "../../../utils/errors";
import aboutimage from "../../../assets/images/coverimage2.jpg";
import { TabTitle } from "../../../utils/dynamicTitle";
const AboutUs = () => {
  TabTitle("RT Pharmaceuticals | About");
  const { slug } = useParams();
  const [Data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    getApiData();
  }, [slug]);
  async function getApiData() {
    try {
      const response = await axios(`https://admin.rtpharma.com.np/api/about`);
      setData(response.data);
      // console.log("res_about", response);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }
  console.log(slug);
  if (loading) return <Loading />;
  if (error) return <Errors />;
  return (
    <>
      <div className="about-section">
        <div className="hero-section">
          <h1>About RT</h1>
        </div>
        <div className="our-story">
          <div className="container">
            {Data.items
              ? Data.items.map((i) => {
                  return (
                    <>
                      <div className="section-1 row">
                        <div className="col-6 imgss">
                          <LazyLoad>
                            <img
                              src={i.CoverImage ? i.CoverImage : aboutimage}
                              alt={i.PageTitle ? i.PageTitle : ""}
                            />
                          </LazyLoad>
                        </div>
                        <div className="col-6 story-details">
                          <h5>{i.PageTitle}</h5>
                          <p>{i.Description.replace(/(<([^>]+)>)/gi, "")}</p>
                        </div>
                      </div>
                    </>
                  );
                })
              : ""}
            {/* <div className="section-1 row">
              <div className="col-6">
                <img src="assets/images/about-us/s1.jpg" alt="" />
              </div>
              <div className="col-6 story-details">
                <h5>The Idea</h5>
                <p>
                  In healthcare, the information around our medicines and lab
                  tests is either unavailable or incomprehensible to us. So we
                  decided to create a platform that stood for transparent,
                  authentic and accessible information for all. This idea grew
                  into a company and RT Pharma was created in 2015.
                </p>
              </div>
            </div>
            <div className="section-2 row">
              <div className="col-6 story-details">
                <h5>What We Offer</h5>
                <p>
                  In healthcare, the information around our medicines and lab
                  tests is either unavailable or incomprehensible to us. So we
                  decided to create a platform that stood for transparent,
                  authentic and accessible information for all. This idea grew
                  into a company and RT Pharma was created in 2015.
                </p>
              </div>
              <div className="col-6">
                <img src="assets/images/about-us/s2.jpg" alt="" />
              </div>
            </div>
            <div className="section-3 row">
              <div className="col-6">
                <img src="assets/images/about-us/s3.jpg" alt="" />
              </div>
              <div className="col-6 story-details">
                <h5>Journey So Far</h5>
                <p>
                  In healthcare, the information around our medicines and lab
                  tests is either unavailable or incomprehensible to us. So we
                  decided to create a platform that stood for transparent,
                  authentic and accessible information for all. This idea grew
                  into a company and RT Pharma was created in 2015.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default AboutUs;
