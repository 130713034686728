import React from "react";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="upper-footer row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
            <div className="col contacts">
              <i className="fa-solid fa-location-dot">
                <p>RT Pharma House, Kathmandu 3, Bagmati, Nepal</p>
              </i>
              <i className="fa-solid fa-mobile">
                <p>(+977-1) 5529042, 5529412</p>
              </i>
              <i className="fa-solid fa-envelope">
                <p>mail@rtpharma.com.np</p>
              </i>
              <i className="fa-brands fa-square-facebook">
                <p>facebook.com/rtpharma</p>
              </i>
              <i className="fa-brands fa-linkedin">
                <p>linkedin.com/rtpharma</p>
              </i>
            </div>
            <div className="col">
              <ul className="list-unstyled">
                <li>
                  <a href="index.php">Home</a>
                </li>
                <li>
                  <a href="about.php">About Company</a>
                </li>
                <li>
                  <a href="product.php">About Products</a>
                </li>
              </ul>
            </div>
            <div className="col">
              <ul className="list-unstyled">
                <li>
                  <a href="media.php">News and Events</a>
                </li>
                <li>
                  <a href="media.php">Media Assets</a>
                </li>
                <li>
                  <a href="career.php">Careers</a>
                </li>
              </ul>
            </div>
            <div className="col">
              <ul className="list-unstyled">
                <li>
                  <a href="contact.php">Contact Us</a>
                </li>
                <li>
                  <a href="#">E-Reporting</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="copyright row text-center">
            <p>© 2023 RT Pharmaceuticals Laboratory Pvt. Ltd.</p>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
