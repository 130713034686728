import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AboutUs from "../components/pages/about";
import ProductList from "../components/pages/products";
import Careers from "../components/pages/careers";
import Blogs from "../components/pages/blogs";
import Contact from "../components/pages/contact";
import NoPage from "./nopage";
const Pages = () => {
  const { slug } = useParams();
  if (slug == "about") {
    return <AboutUs />;
  } else if (slug == "products") {
    return <ProductList />;
  } else if (slug == "careers") {
    return <Careers />;
  } else if (slug == "blogs") {
    return <Blogs />;
  } else if (slug == "contacts") {
    return <Contact />;
  } else {
    return <NoPage />;
  }
};
export default Pages;
