import React, { useEffect, useState } from "react";
import axios from "axios";
import LazyLoad from "react-lazy-load";
import Loading from "../../../utils/loading";
import { Link, useParams, useNavigate } from "react-router-dom";
import Errors from "../../../utils/errors";
import Form from "./form";
const Contact = () => {
  const { slug } = useParams();
  const [Data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    getApiData();
  }, [slug]);
  async function getApiData() {
    try {
      const response = await axios(
        `https://admin.rtpharma.com.np/api/contacts`
      );
      setData(response.data);
      console.log("res_contact", response);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }
  console.log(slug);
  if (loading) return <Loading />;
  if (error) return <Errors />;
  return (
    <>
      <div className="hero-section">
        <h1>Contact Us</h1>
      </div>
      <div className="contact-section">
        <div className="container">
          <div className="row">
            <div className="contact-details col-5">
              <div className="contact-back">
                <div className="details">
                  <i className="fa-solid fa-location-dot">
                    <p>
                      {Data.site_settings.contact_detail
                        ? Data.site_settings.contact_detail.replace(
                            /(<([^>]+)>)/gi,
                            ""
                          )
                        : "RT Pharma"}
                    </p>
                  </i>
                  <i className="fa-solid fa-mobile">
                    <p>
                      {Data.site_settings.telephone
                        ? Data.site_settings.telephone
                        : "+977"}
                      ,{" "}
                      {Data.site_settings.mobile
                        ? Data.site_settings.mobile
                        : "+977"}
                    </p>
                  </i>
                  <i className="fa-solid fa-envelope">
                    <p>
                      {Data.site_settings.email
                        ? Data.site_settings.email
                        : "info@rtpharma.com.np"}
                    </p>
                  </i>
                </div>
                <div className="map">
                  <LazyLoad>
                    <iframe
                      src={
                        Data.site_settings.map_location
                          ? Data.site_settings.map_location.replace(
                              /(<([^>]+)>)/gi,
                              ""
                            )
                          : ""
                      }
                      style={{ border: 0 }}
                      allowFullScreen
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    />
                  </LazyLoad>
                </div>
                <div className="socials">
                  <h5>follow us on:</h5>
                  <div className="social-logo">
                    <Link
                      to={
                        Data.site_settings !== null
                          ? Data.site_settings.facebook
                          : ""
                      }
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook col-sm-2" />
                    </Link>
                    <Link
                      to={
                        Data.site_settings !== null
                          ? Data.site_settings.instagram
                          : ""
                      }
                      target="_blank"
                    >
                      <i className="fa-brands fa-instagram col-sm-2" />
                    </Link>
                    <Link
                      to={
                        Data.site_settings !== null
                          ? Data.site_settings.linked_in
                          : ""
                      }
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin col-sm-2" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-form col-7">
              <h2>Want to Know More? Drop Us Your Queries</h2>
              <Form />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;
