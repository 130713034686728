import useForm from "./useform";
import { useNavigate } from "react-router-dom";
const FORM_ENDPOINT = "https://admin.rtpharma.com.np/api/contacts/form";

const Form = () => {
  const navigate = useNavigate();
  const additionalData = {
    sent: new Date().toISOString(),
  };

  const { handleSubmit, status, message } = useForm({
    additionalData,
  });

  if (status === "success") {
    return (
      // <div className="alert alert-primary">
      //   <div className="text-2xl">Thank you!</div>
      //   <div className="text-md">{message}</div>
      //   <div></div>
      // </div>
      <div className="messs">
        <div>
          <div class="success-checkmark">
            <div class="check-icon">
              <span class="icon-line line-tip"></span>
              <span class="icon-line line-long"></span>
              <div class="icon-circle"></div>
              <div class="icon-fix"></div>
            </div>
          </div>
          <div className="text-2xl">Thank you!</div>
          <div className="text-md">{message}</div>
          <center>
            <button
              className="btn btn-success"
              onClick={() => {
                navigate(`/`);
              }}
            >
              ok
            </button>
          </center>
        </div>
      </div>
    );
  }

  if (status === "error") {
    return (
      <>
        <div className="messs">
          <div>
            {/* <div class="success-checkmark">
            <div class="check-icon">
              <span class="icon-line line-tip"></span>
              <span class="icon-line line-long"></span>
              <div class="icon-circle"></div>
              <div class="icon-fix"></div>
            </div>
          </div> */}
            <div className="text-2xl">Something bad happened!</div>
            <div className="text-md">{message}</div>
            <center>
              <button
                className="btn btn-success"
                onClick={() => {
                  navigate(`/`);
                }}
              >
                ok
              </button>
            </center>
          </div>
        </div>
      </>
    );
  }

  return (
    <form action={FORM_ENDPOINT} onSubmit={handleSubmit} method="POST">
      <input
        className="form-control"
        type="text"
        name="first_name"
        placeholder="First Name"
      />
      <input
        className="form-control"
        type="text"
        name="last_name"
        placeholder="Last Name"
      />
      <input
        className="form-control"
        type="email"
        name="email"
        placeholder="Email Address"
      />

      <input
        className="form-control"
        type="number"
        name="mobno"
        placeholder="Phone Number"
      />

      <textarea
        className="form-control"
        name="subject"
        id="contact-message"
        cols={30}
        rows={6}
        placeholder="Your message here"
      />
      <input type="submit" placeholder="Submit" />
    </form>
  );
};

export default Form;
