import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
const TopNavBar = () => {
  const { slug } = useParams();
  const [Data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    getApiData();
  }, [slug]);
  async function getApiData() {
    try {
      const response = await axios(
        `https://admin.rtpharma.com.np/api/Site_settings`
      );
      setData(response.data);
      // console.log("res_top", response);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }
  if (loading) return "";
  if (error) return "";
  return (
    <>
      <div className="small-nav">
        <div className="container">
          <div className="row">
            <div className="nav-contacts col-sm-8">
              <a href="#">
                <i className="fa-solid fa-mobile" />
                {Data.site_settings !== null
                  ? Data.site_settings.telephone
                  : ""}
              </a>
              <a href="#">
                <i className="fa-solid fa-envelope" />
                {Data.site_settings !== null ? Data.site_settings.email : ""}
              </a>
              <ul className="list-unstyled d-flex"></ul>
            </div>
            <div className="nav-socials col-sm-4">
              <Link
                to={
                  Data.site_settings !== null ? Data.site_settings.facebook : ""
                }
                target="_blank"
              >
                <i className="fa-brands fa-facebook col-sm-2" />
              </Link>
              <Link
                to={
                  Data.site_settings !== null
                    ? Data.site_settings.instagram
                    : ""
                }
                target="_blank"
              >
                <i className="fa-brands fa-instagram col-sm-2" />
              </Link>
              <Link
                to={
                  Data.site_settings !== null
                    ? Data.site_settings.linked_in
                    : ""
                }
                target="_blank"
              >
                <i className="fa-brands fa-linkedin col-sm-2" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TopNavBar;
