import React, { useEffect, useState } from "react";
import axios from "axios";
import LazyLoad from "react-lazy-load";
import Loading from "../../../utils/loading";
import { Link, useParams, useNavigate } from "react-router-dom";
import Errors from "../../../utils/errors";
import Logo from "../../../assets/images/logo.jpg";
const ProductDetails = () => {
  const { slug } = useParams();
  const [Data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    getApiData();
  }, [slug]);
  async function getApiData() {
    try {
      const response = await axios(
        `https://admin.rtpharma.com.np/api/products`
      );
      setData(response.data);

      console.log("de", response);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }

  if (loading) return <Loading />;
  if (error) return <Errors />;
  return (
    <>
      <div className="product-details-section">
        <div className="container">
          {Data.items
            ? Data.items.map((i) => {
                if (slug == i.slug) {
                  return (
                    <div className="product-title" key={i.id}>
                      <h3>{i.title}</h3>
                      <p>{i.category}</p>
                    </div>
                  );
                }
              })
            : ""}

          <div className="product-details row">
            <div className="product-info col-md-5 col-lg-6">
              {Data.items
                ? Data.items.map((i) => {
                    if (slug == i.slug) {
                      return (
                        <div className="product-img" key={i.id}>
                          <LazyLoad>
                            <img
                              src={i.featured_image ? i.featured_image : Logo}
                              alt=""
                            />
                          </LazyLoad>
                        </div>
                      );
                    }
                  })
                : ""}
            </div>
            <div className="product-desc col-md-7 col-lg-6">
              {Data.items
                ? Data.items.map((i) => {
                    if (slug == i.slug) {
                      return (
                        <p key={i.id}>
                          {i.Description.replace(/(<([^>]+)>)/gi, "")}
                        </p>
                      );
                    }
                  })
                : ""}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductDetails;
