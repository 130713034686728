import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import NavBar from "./components/navbar";
import HomePage from "./components/homepage";
import ProductDetails from "./components/pages/products/details";
import BlogsDetails from "./components/pages/blogs/details";
import Pages from "./utils/routes";
import Footer from "./components/footer";
function App() {
  const [loading, setLoading] = useState(true);
  const spinner = document.getElementById("spinner");
  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    }, 3000);
  }
  return (
    // <BrowserRouter>
    //   <NavBar />
    //   <Routes>
    //     <Route path="/" element={<HomePage />} />
    //     <Route path="/:slug" element={<Pages />} />
    //     <Route path="products/details/:slug" element={<ProductDetails />} />
    //     <Route path="blogs/details/:slug" element={<BlogsDetails />} />
    //   </Routes>
    //   <Footer />
    // </BrowserRouter>
    !loading && (
      <>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/:slug" element={<Pages />} />
          <Route path="products/details/:slug" element={<ProductDetails />} />
          <Route path="blogs/details/:slug" element={<BlogsDetails />} />
        </Routes>
        <Footer />
      </>
    )
  );
}

export default App;
