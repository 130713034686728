import React, { useEffect, useState } from "react";
import axios from "axios";
import LazyLoad from "react-lazy-load";
import Loading from "../../../utils/loading";
import { Link, useParams, useNavigate } from "react-router-dom";
import Errors from "../../../utils/errors";
import { TabTitle } from "../../../utils/dynamicTitle";
const Careers = () => {
  TabTitle("RT Pharmaceuticals | Careers");
  const { slug } = useParams();
  const [Data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    getApiData();
  }, [slug]);
  async function getApiData() {
    try {
      const response = await axios(
        `https://admin.rtpharma.com.np/api/products`
      );
      setData(response.data);
      // console.log("res_about", response);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }
  console.log(slug);
  if (loading) return <Loading />;
  if (error) return <Errors />;
  return (
    <>
      <div>
        <div className="career-hero-section">
          <div className="hero-section">
            <h1>Careers</h1>
          </div>
        </div>
        <div className="container">
          <div className="job-listing">
            <h3>New Job Listing</h3>
            <ul className="list-unstyled">
              <li>
                <a href="career-details.php">
                  <h5>Marketing Representative</h5>
                  <button className="custon-btn-1">Apply Now</button>
                </a>
              </li>
              <li>
                <a href="career-details.php">
                  <h5>Marketing Representative</h5>
                  <button className="custon-btn-1">Apply Now</button>
                </a>
              </li>
              <li>
                <a href="career-details.php">
                  <h5>Marketing Representative</h5>
                  <button className="custon-btn-1">Apply Now</button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default Careers;
