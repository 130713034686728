import React, { useEffect, useState } from "react";
import axios from "axios";
import LazyLoad from "react-lazy-load";
import Loading from "../../../utils/loading";
import { Link, useParams, useNavigate } from "react-router-dom";
import Errors from "../../../utils/errors";
import Logo from "../../../assets/images/logo.jpg";
import ReactPaginate from "react-paginate";
import { TabTitle } from "../../../utils/dynamicTitle";
const ProductList = () => {
  TabTitle("RT Pharmaceuticals | Products");
  const { slug } = useParams();
  const [Data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    getApiData();
  }, [slug]);
  async function getApiData() {
    try {
      const response = await axios(
        `https://admin.rtpharma.com.np/api/products?_page=1&_limit=6`
      );
      setData(response.data.items);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }
  const fetchData = async (currentPage) => {
    const response = await axios(
      `https://admin.rtpharma.com.np/api/products?_page=${currentPage}&_limit=6`
    );
    setData(response.data.items);
  };
  const handlePageClick = async (data) => {
    console.log(data.selected);

    let currentPage = data.selected + 1;
    const dataFromServer = await fetchData(currentPage);
    setData(dataFromServer);
  };
  console.log(Data);
  if (loading) return <Loading />;
  if (error) return <Errors />;
  return (
    <>
      <div className="product-page">
        <div className="hero-section">
          <h1>RT Products</h1>
        </div>

        <div className="container">
          <div className="all-products">
            <div className="row row-cols-1 row-cols-md-3 g-4">
              {Data
                ? Data.map((i) => {
                    return (
                      <div className="col" key={i.id}>
                        <Link to={`details/${i.slug}`}>
                          <LazyLoad>
                            <div className="card">
                              <img
                                src={i.featured_image ? i.featured_image : Logo}
                                className="card-img-top"
                                alt={i.title}
                              />

                              <div className="card-body">
                                <h5 className="card-title">{i.title}</h5>
                              </div>
                            </div>
                          </LazyLoad>
                        </Link>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="Paginationss">
        {/* <Pagination /> */}
        <ReactPaginate
          previousLabel={`Previous`}
          nextLabel={`Next`}
          breakLabel={`...`}
          pageCount={10}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          activeClassName={"active"}
          activeLinkClassName={"page-link"}
          disabledClassName={"disabled"}
          disabledLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
        />
      </div>
    </>
  );
};
export default ProductList;
