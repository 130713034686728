import React, { useEffect, useState } from "react";
import axios from "axios";
import LazyLoad from "react-lazy-load";
import Loading from "../../../utils/loading";
import { useParams } from "react-router-dom";
import Errors from "../../../utils/errors";
import Logo from "../../../assets/images/logo.jpg";
const BlogsDetails = () => {
  const { slug } = useParams();
  const [Data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    getApiData();
  }, [slug]);
  async function getApiData() {
    try {
      const response = await axios(`https://admin.rtpharma.com.np/api/blogs`);
      setData(response.data);

      console.log("des", response);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }

  if (loading) return <Loading />;
  if (error) return <Errors />;
  return (
    <>
      {Data.items
        ? Data.items.map((i) => {
            if (slug == i.slug) {
              return (
                <>
                  <div className="blogpage" key={i.id}>
                    <div className="blogsimage">
                      <LazyLoad>
                        <img
                          src={i.featured_image ? i.featured_image : Logo}
                          alt={i.title ? i.title : ""}
                        />
                      </LazyLoad>
                    </div>
                    <div className="blogsauth">
                      <h1>{i.title ? i.title : ""}</h1>
                      <p>
                        <span>
                          <i className="fa fa-calendar-check"> </i> Published on
                          : {i.created ? i.created : ""}
                        </span>
                        <span>
                          <i className="fa fa-user"> </i>Published By :
                          {i.created_by ? i.created_by : ""}
                        </span>
                      </p>
                    </div>
                    <div className="blogsdescription">
                      <p>
                        {i.description.replace(/(<([^>]+)>)/gi, "")
                          ? i.description.replace(/(<([^>]+)>)/gi, "")
                          : ""}
                      </p>
                    </div>
                  </div>
                </>
              );
            }
          })
        : ""}
    </>
  );
};
export default BlogsDetails;
