import React from "react";
import { Link } from "react-router-dom";
import logos from "../assets/images/logo.jpg";
const NoPage = () => {
  return (
    <>
      <div className="errors">
        <div>
          <img src={logos} />
          <h3>Page Not Found</h3>
          <p></p>
          <p>
            <Link to="/">Return to Home Page</Link>
          </p>
        </div>
      </div>
    </>
  );
};
export default NoPage;
