import React, { useEffect, useState } from "react";
import axios from "axios";
import LazyLoad from "react-lazy-load";
import Loading from "../../../utils/loading";
import { Link, useParams, useNavigate } from "react-router-dom";
import Nodata from "../../../utils/nodata";
import { TabTitle } from "../../../utils/dynamicTitle";
const Blogs = () => {
  TabTitle("RT Pharmaceuticals | Blogs");
  const { slug } = useParams();
  const [Data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    getApiData();
  }, [slug]);
  async function getApiData() {
    try {
      const response = await axios(`https://admin.rtpharma.com.np/api/blogs`);
      setData(response.data);
      console.log("res_blogs", response);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }
  console.log(slug);
  if (loading) return <Loading />;
  if (error) return "error";
  return (
    <>
      <div className="hero-section">
        <h1>RT Blogs</h1>
      </div>
      <div className="blogs_content container">
        {Data.items ? (
          Data.items.map((i) => {
            const newtext = i.description.replace(/(<([^>]+)>)/gi, "");
            return (
              <div className="blogs_itemss row" key={i.id}>
                <div className="col-sm-12 col-lg-6">
                  <div className="blogs_image">
                    <LazyLoad>
                      <img src={i.featured_image} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6">
                  <div className="blogs_contents">
                    <h3>{i.title}</h3>
                    <p>
                      {newtext.length > 200
                        ? `${newtext.substring(0, 200)}...`
                        : newtext}
                    </p>
                    <p className="publishdate">
                      <span>Published on : </span>
                      {i.created}
                    </p>
                    <Link className="view_More" to={`details/${i.slug}`}>
                      <i className="fa-solid fa-arrow-right-long"></i>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <Nodata />
        )}
      </div>
    </>
  );
};
export default Blogs;
