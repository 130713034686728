import React from "react";
import { Link } from "react-router-dom";
import logos from "../assets/images/logo.jpg";
const Errors = () => {
  return (
    <>
      <div className="errors">
        <div>
          <img src={logos} />
          <h3>Connect to the internet</h3>
          <p>You're offline. Check your connection.</p>
          <p>
            <a href="/">Retry</a>
          </p>
        </div>
      </div>
    </>
  );
};
export default Errors;
